.white {
    color: #fff !important;
}

.icon-size {
    font-size: 20px;
}

.aivolvex-profile-outline {
    top: 3rem;
    /* margin: 5px; */
    width: 100%;
}

.pro-body {
    background-color: #10112c;
}

.profile-card {
    border-radius: 0.625rem;
    border: transparent;
    background: #17193a;
    backdrop-filter: blur(5.627785682678223px);
    color: #fff !important;
    /* width: 100%; */
    /* display: flex;
      justify-content: center; */
    width: 50% !important;
    /* width: 40rem !important; */
}

.profile-card .ant-input:focus,
.profile-card .ant-select:focus .ant-select-selector,
.profile-card .ant-input:hover,
.profile-card .ant-color-picker-trigger:hover,
.profile-card .ant-select:hover .ant-select-selector {
    border-color: #d9d9d9 !important;
}

.profile-card.ant-card .ant-card-head-title {
    color: #d736d1 !important;
    font-size: 18px;
    display: flex;
    justify-content: center;
}

.aivolvex-profile-outline .ant-color-picker-input {
    color: #000 !important;
}

.profile-card .ant-upload .ant-btn-default {
    background-color: transparent !important;
    color: #848484 !important;
    border-color: #d9d9d9 !important;
}

.ant-color-picker .ant-color-picker-inner-content .ant-color-picker-input-container .ant-color-picker-input .ant-color-picker-hex-input.ant-input-affix-wrapper .ant-input {
    color: #000 !important;
}

.ant-color-picker-trigger {
    background-color: transparent !important;
    color: #fff !important;
}

.ant-color-picker-trigger .ant-color-picker-trigger-text {
    color: #fff !important;
}

.card-design {
    display: flex;
    justify-content: center;
}

.profile-card .ant-upload-wrapper .ant-upload-list {
    padding: 5px !important;
}

.profile-card .ant-btn-primary {
    color: #fff !important;
    background-color: #d736d1 !important;
    border: none !important;
    margin: 2px;
}

.submit-button-form {
    display: flex;
    justify-content: end;
}