/* start desktop */
:root {
  --chat_site_color: #17193a;
  --chatblue-gray-color: #24264c;
  --chat_background_color: #080a1e;
  --chat_lite_site_color: #e7e7e7;
  --white-color: #fff;
  --purple-color: #d736d1;
  --gray_backgroud_color: #3a3d63;
}

/* color: var(--costimize_site_color); */
/* BảoVui font */
@import url("https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.desktop-conversaction-body {
  min-height: 100vh;
  height: auto;
}
.message-background {
  background: #292b55;
}

.volvex-avatar-list-body {
  padding: 6.5rem 1rem 1rem;
  background: #10112c;
  height: auto;
}

.volvex-chat-body {
  padding: 3.5rem 0rem 0rem;
  background-color: #17193a;
  height: auto;
}

@keyframes blink {
  0%,
  50%,
  100% {
    opacity: 1;
  }

  25%,
  75% {
    opacity: 0;
  }
}

.recording-icon {
  animation: blink 2s infinite;
  font-size: 22px;
  color: #fff;
}

.recording-icon-active {
  font-size: 22px;
  color: #fff;
}

.volvex-chat-body {
  background-image: url("../../../assets/chat/chat-bg.svg");
  background-position: center center;
  background-size: 35% 35%;
  background-repeat: no-repeat;
  height: auto;
}

.volvex-chat-header {
  color: #fff;
  padding: 1.4rem 1rem 0.6rem;
  background: rgba(255, 255, 255, 0.04);
}

/* start chat part */
.conversation-responce-pre {
  word-wrap: break-word;
  overflow: auto;
  white-space: pre-wrap;
  font-family: unset;
  font-size: 0.875rem;
  line-height: 1.7;
  font-weight: 400;
  margin: 0px;
}

.baovui-chat-font {
  font-family: "Merriweather", serif !important;
  font-size: 0.94rem !important;
}

.pre-text-source {
  display: flex;
  justify-content: right;
}

.conversation-responce-pre::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

.conversation-responce-pre::-webkit-scrollbar-track {
  background-color: #fae8f6;
  border-radius: 0.5rem;
}

.conversation-responce-pre::-webkit-scrollbar-thumb {
  background-color: #9a9a9a;
  border-radius: 10px;
  border: 2px solid #fae8f6;
}

.volvex-question-chat {
  color: #fff;
  width: 100%;
}

.text-source {
  width: 100% !important;
}

.volvex-chat-avatar-logo {
  width: 40px;
  height: 40px;
  border-radius: 40px;
}

.footer-chat-time {
  font-size: 10px;
  font-weight: 500;
  color: #646572;
}

.volvex-chat-avatar-img-left {
  margin-left: 1rem;
}

.volvex-chat-avatar-img-right {
  margin-right: 1rem;
}

.volvex-question-chat-body {
  border-radius: 15px;
  border: none !important;
  background: rgba(255, 255, 255, 0.11);
  padding: 1rem;
  margin: 0rem 0.5rem 0rem 1.5rem;
}

.volvex-question-chat-body-mobile {
  /* border-radius: 15px; */
  border: none !important;
  /* background: rgba(255, 255, 255, 0.11);
   */
  border-radius: 11px;
  background: rgba(41, 43, 85, 0.5);
  padding: 1rem;
  margin: 0rem 0.5rem 0rem 0.5rem;
}

.volvex-ans-chat-body {
  border-radius: 10px;
  background: #292b55;
  padding: 1rem;
  margin: 0rem 0.5rem 0rem 1.5rem;
}

.volvex-ans-chat-body-mobile {
  border-radius: 10px;
  background: #292b55;
  padding: 1rem;
  margin: 0rem 0.5rem 0rem 0.5rem;
}

.graph-icon {
  color: #fff;
  font-size: 22px;
}

.feedback-icon {
  margin: 0px 3px;
  cursor: pointer;
  color: #4d4f6e;
  font-size: 21px;
}

.feedback-icon-active {
  margin: 0px 3px;
  cursor: pointer;
  color: #fff;
  font-size: 21px;
}

.volvex-submit-btn {
  background: var(--chatblue-gray-color);
}

@keyframes blink {
  0%,
  50%,
  100% {
    opacity: 1;
  }

  25%,
  75% {
    opacity: 0;
  }
}

.recording-icon {
  animation: blink 2s infinite;
  font-size: 22px;
  color: #fff;
}

.recording-icon-active {
  font-size: 22px;
  color: #fff;
}

.upload-icon {
  font-size: 21px;
  display: inline-block;
  background-color: #24264c;
  color: white;
  margin-bottom: 4px;
  padding: 3px 16px;
}

.volvex-chat-token-cal {
  background: #24264c;
  color: #fff;
  margin-top: 20px;
  height: 2.5rem;
  border-radius: 8px;
}

.volvex-chat-token-logo-img {
  width: 23px;
}

.volvex-submit-btn {
  background: #24264c;
}

.custom-file-upload {
  display: inline-block;
  background-color: #24264c;
  margin: 0px;
  padding: 0px;
  cursor: pointer;
}

.about-header-icon {
  font-size: 30px;
}

.about-header-icon-library {
  font-size: 28px;
}

.volvex-chat-right-bar-border {
  margin: 0.5rem 0rem;
  border: 1px solid #727383;
}

.right-bar-font {
  font-size: 12px;
  font-weight: 400;
}

/* end chat part */
/* start collapse */
.volvex-avatar-list-collapse {
  overflow-y: auto;
  /* height: 34rem !important; */
}

.volvex-avatar-list-collapse .ant-collapse-header-text {
  color: #fff !important;
  font-size: 20px;
  font-weight: 500;
}

.volvex-avatar-list-collapse .ant-collapse-arrow {
  color: #fff !important;
  font-size: 11px !important;
  margin-top: 0.8rem;
}

.volvex-avatar-list-collapse-drawar .ant-collapse-header-text {
  color: #fff !important;
  font-size: 20px;
  font-weight: 500;
}

.volvex-avatar-list-collapse-drawar .ant-collapse-arrow {
  color: #fff !important;
  font-size: 11px !important;
  margin-top: 0.8rem;
}

.volvex-sidemenu-list-avatar-logo {
  width: 29px;
  height: 29px;
  border-radius: 29px;
}

.volvex-sidemenu-list-avatar-name {
  color: #fff;
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  display: flex;
  align-items: center;
  padding-left: 0.4rem;
}

.volvex-sidemenu-list-token-value {
  color: #fff;
  font-size: 15px;
  font-weight: 400;
}

.volvex-sidemenu-list-token-logo {
  padding-left: 0.2rem;
}

.volvex-avatar-list-footer {
  background: #17193a;
  border: 1px solid #424352;
  padding: 15px;
  color: #fff;
  border-radius: 11px;
  font-size: 14px;
  font-weight: 400;
  margin: 2rem 1rem;
}

.test {
  padding: 0 10px;
  border-top: 1px solid #fff;
  height: 1px;
  line-height: 0.1em;
}

/* end collapse */
/* start scrollbar */
.volvex-avatar-list-collapse::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.volvex-avatar-list-collapse::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 0.5rem;
}

.volvex-avatar-list-collapse::-webkit-scrollbar-thumb {
  background-color: transparent;
  border-radius: 10px;
  border: 1px solid #9a9a9a;
}

/* end scrollbar */
.volvex-chat-avatar-title {
  display: flex;
  align-items: center;
  padding-left: 0.5rem;
  font-size: 18px;
  font-weight: 400;
}

.volvex-chat-header-avatar-logo {
  width: 30px;
  height: 30px;
  border-radius: 40px;
}

.volvex-chat-header-icon {
  font-size: 19px;
  margin: 0rem 0.3rem;
}

/* .side-scroll-container-chat {
    height: 37rem !important;
  } */

.volvex-submit-btn-icon {
  margin-top: -6px;
}

/* end desktop */

/* start drawar */
.volvex-drawar .ant-drawer-body {
  padding: 1px !important;
}

/* .title-prompt{
    background: linear-gradient(180deg, rgba(215, 54, 209, 0.21) 0%, rgba(255, 255, 255, 0) 468.03%) !important;
  }
  .title-prompt-time{
    padding: 13px;
  } */
.volvex-drawar .ant-drawer-wrapper-body {
  background: #10112c;
}

.volvex-drawar .ant-drawer-close {
  color: #fff;
}

/* end drawar */

/* start popconfirm */

/* end popconfirm */

.ant-popover-content {
  border-radius: 14px;
  box-shadow: 5px 8px 37px 0px rgba(0, 0, 0, 0.25);
}

.ant-popover-inner {
  width: 27rem;
  background: #10112c !important;
  padding: 1.5rem !important;
}

.ant-popover .ant-popover-arrow::before {
  background: #10112c !important;
}

.ant-popover .ant-popover-arrow::after {
  background: #10112c !important;
}

.volvex-about-title {
  color: #d736d1;
  font-size: 21px;
  margin-left: 0.3rem;
}

.ant-popconfirm-message-icon {
  display: none;
}

.volvex-about-body {
  width: 100%;
}

.ant-popconfirm-message-text {
  width: 100%;
}

.volvex-shared-form-title {
  color: #db2aac;
  font-size: 18px;
  font-weight: 600;
}

.volvex-shared-form {
  width: 100%;
}

.volvex-shared-form-title-body {
  padding-bottom: 1rem;
  border-bottom: 1px solid #5a5656;
}

.ant-select-selector {
  background: #24264c !important;
}

.volvex-about-avatar-logo-body {
  display: flex;
  align-items: center;
  justify-content: center;
}

.volvex-about-avatar-logo {
  border-radius: 4rem;
  width: 130px;
  margin: 1.5rem 0rem 1rem;
}

.volvex-about-avatar-logo-mobile {
  border-radius: 4rem;
  width: 60px;
  margin: 0.5rem 0rem 0.5rem;
}

.volvex-about-avatar-title {
  color: #fff;
  display: flex;
  justify-content: center;
  font-size: 19px;
  font-weight: 500;
}

.volvex-about-avatar-title {
  color: #fff;
  display: flex;
  justify-content: center;
  font-size: 16px;
  font-weight: 500;
}

.avatar-info-icons {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}

.avatar-info-img {
  width: 19px;
}

.info-icon-text {
  font-size: 14px;
  margin-left: 0.4rem;
  font-weight: 400;
}

.volvex-about-token-list {
  border-radius: 10px;
  background: #1b1f42;
  margin: 1rem 0rem;
  padding: 0.5rem 0rem;
}

.volvex-info-header-icon {
  font-size: 21px;
  margin-right: 0.6rem;
}

.lib-history {
  border: 1px solid #353657 !important;
  background: linear-gradient(to top, rgb(23 25 58), transparent);
  /* background: #24264c !important; */
  padding: 5px;
  border: none;
  width: 97%;
  /* height: 48px; */
  border-radius: 10px;
}

.volvex-new-avatar {
  color: #fff;
  font-size: 20px;
  font-weight: 500;
}

.volvex-new-avatar-mobile {
  color: var(--purple-color);
  font-size: 16px;
  font-weight: 600;
  font-family: "Poppins";
}

.volvex-new-font-mobile {
  font-size: 10px;
  font-family: "Poppins";
}

.volvex-view-source-font-mobile {
  font-size: 14px;
  font-weight: 600;
  font-family: "Poppins";
}

.ant-popconfirm-title {
  color: #fff !important;
  font-size: 18px;
  font-weight: 500 !important;
}

.volvex-conversation-history-view {
  color: #fff;
  font-size: 11px;
}

.volvex-chat-right-bar {
  color: #fff;
  padding: 4.6rem 0.4rem 0.6rem;
  background: #07081b;
}

.volvex-search-box .ant-input {
  background: transparent !important;
}

.volvex-view-source-font {
  font-size: 16px;
  font-weight: 600;
}

.pinned-color {
  color: #802588;
}

.ant-divider-horizontal {
  background: #848484 !important;
}

.view-src.ant-modal .ant-modal-title {
  color: #d736d1 !important;
  font-size: 25px !important;
  font-weight: bold !important;
}

.view-txt {
  color: #d736d1 !important;
}

.view-txt-header-right {
  font-size: 20px !important;
  font-weight: bold !important;
}

.view-header-txt {
  color: #fff !important;
  font-size: 17px !important;
  font-weight: bold !important;
}

.view-body-txt {
  padding: 5px;
}

li.ant-pagination-item {
  color: #fff;
  border: 1px solid #fff !important;
}

.view-src .ant-pagination .ant-pagination-next .ant-pagination-item-link {
  border-radius: 0px 4dvb 4px 0px;
  color: #fff !important;
  border: 1px solid #fff !important;
  border-radius: 0px !important;
}

.view-src .ant-pagination .ant-pagination-prev .ant-pagination-item-link {
  border-radius: 4px 0px 0px 4px;
  color: #fff !important;
  border: 1px solid #fff !important;
  border-radius: 0px !important;
}

.view-src .ant-pagination .ant-pagination-item {
  margin: 0px;
  border-radius: 0px !important;
  border: 1px solid #fff !important;
}

.view-src .ant-pagination .ant-pagination-item {
  margin: 0px;
  border-radius: 0px !important;
  border: 1px solid #fff !important;
}

li.ant-pagination-prev.ant-pagination-disabled {
  margin: 0px;
}

.view-src .ant-table-wrapper .ant-table-pagination.ant-pagination {
  margin: 16px !important;
  margin-right: 16px !important;
}

.view-src .ant-pagination .ant-pagination-item-active {
  background: rgba(255, 255, 255, 0.5) !important;
  color: #fff !important;
}

.view-source {
  height: 60vh;
  overflow-y: scroll;
}

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-thumb {
  background: #ffffff69;
  border-radius: 10px;
}

.view-source {
  padding: 10px;
}

.view-location {
  padding-left: 3rem;
}

.upload-file-pdf
  .ant-upload-wrapper
  .ant-upload-list
  .ant-upload-list-item-error
  .ant-upload-list-item-actions
  .anticon:hover {
  color: red;
}

.upload-file-pdf
  .ant-upload-wrapper
  .ant-upload-list
  .ant-upload-list-item-error
  .ant-upload-list-item-actions
  .anticon {
  font-size: 16px;
}

/* new scroll change */
.scroll-body-slice {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.scroll-main-slice1 {
  flex: 1;
  overflow-y: auto;
}

/* end new scroll change */

/* MobileviewCSS */
.conversation-mobile {
  display: none !important;
}

.chat-header-logo-title {
  color: var(--purple-color);
  font-size: 18px;
  font-weight: 600;
  font-family: "Poppins";
  padding-top: 0.5rem;
}

.chat-header-part {
  padding: 5px;
  background-color: var(--chat_site_color);
  display: flex;
  padding: 0.5rem;
  justify-content: space-between;
}

.chat-bottom-part {
  background-color: var(--chat_site_color);
  display: flex;
  color: var(--white-color);
  justify-content: space-between;
  padding: 1rem;
  border-top: 1px solid rgba(255, 255, 255, 0.24);
  /* bottom: 0 !important;
  position: absolute; */
}

.chat-mobile-body {
  height: 71vh;
  padding: 5px;
  /* overflow-y: scroll; */
}

.chat-mobilepart-main {
  height: 62vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

.chat-bottom-part-font {
  font-size: 10px;
  text-align: center;
}

.chat-bottom-icon {
  display: flex;
  justify-content: center;
}

a {
  color: var(--white-color);
}

a:active {
  color: var(--purple-color) !important;
}

.chat-agent-name {
  color: var(--white-color);
  font-size: 18px;
  font-weight: 600;
  font-family: "Poppins";
}

.chat-agent-sub-name {
  color: var(--purple-color);
  font-size: 10px;
  text-align: center;
}

.chat-icon-button {
  padding-top: 0.2rem;
}

.chat-icon-inner-button {
  margin-top: -3px;
}

.chat-header-sub-part {
  padding: 5px;
  background-color: var(--chat_site_color);
  display: flex;
  padding: 0.5rem;
  justify-content: space-between;
  border-bottom: 1px solid rgba(255, 255, 255, 0.24);
}

.chat-bottom-section {
}

.chat-cancel-icon {
  color: var(--white-color);
  /* background: var(--gray_backgroud_color); */
  background-color: var(--chatblue-gray-color);
  border: none;
  /* padding-top: 0.2rem; */
}

.chat-icon-inner-button:hover {
  color: #fff;
}

.DashboardNavicon {
  color: #fff;
}

.chat-mobile-body-header {
  display: flex;
  justify-content: center;
}

.chat-add-icon {
  color: var(--white-color);
  /* background: var(--gray_backgroud_color); */
  background-color: var(--purple-color);
  border: none;
}

.chat-add-icon-mobile {
  color: var(--white-color);
  background-color: var(--purple-color);
  border: none;
}

.chat-hearder-time {
  color: rgba(255, 255, 255, 0.42);
  font-size: 8px;
  font-family: Poppins;
}

.ant-btn-default:hover {
  color: #fff;
}

.chat-header-title-image {
  padding-top: 0.8rem;
}

.chat-mobile-pod-drawer {
}

.ant-drawer-content.chat-mobile-pod-drawer .ant-drawer-header {
  display: none;
}

.ant-drawer .ant-drawer-header {
  display: none;
}

.ant-drawer-content.chat-mobile-pod-drawer {
  background: transparent !important;
  margin-top: 7.25rem !important;
  height: 70vh !important;
  width: 80%;
}

.ant-drawer-content.chat-mobile-navbar-draw {
  height: 40vh;
  bottom: 12% !important;
}

.ant-drawer-bottom > .ant-drawer-content-wrapper .chat-mobile-navbar-draw {
  height: 301px;
  padding-top: 5rem;
}

.ant-drawer .ant-drawer-content {
  background-color: transparent;
}

.chat-bottom-navigation {
  display: flex;
  justify-content: center;
}

.chat-bottom-navigation-font {
  color: var(--white-color);
  text-align: center;
}

.chat-bottom-navigation-button {
  background-color: var(--chat_site_color);
  height: 3.3rem;
  /* border: none; */
  border: 1px solid var(--gray_backgroud_color);
}

/* .chat-mobile-navbar-draw > .ant-drawer .ant-drawer-wrapper-body {
  border-top-right-radius: 4px !important;
  border-top-left-radius: 4px !important;
} */
.chat-mobile-navbar-draw .ant-drawer-wrapper-body {
  border-top-right-radius: 1.5rem !important;
  border-top-left-radius: 1.5rem !important;
  background: #0f1027;
}

.chat-input-group {
  /* background-color: var(--chat_site_color); */
  /* background-color: var(--gray_backgroud_color); */
  background-color: #24264c;
  border: none;
}

/* .ant-drawer .ant-drawer-content {
  background: transparent !important;
  margin-top: 7.25rem !important;
  height: 70vh !important;
  width: 80%;
} */

.chat-mobile-bottom-drawer .ant-drawer .ant-drawer-content {
  width: 100vw !important;
}

.chat-mobile-pod-drawer .ant-drawer .ant-drawer-content {
  background: transparent !important;
  margin-top: 7.25rem !important;
  height: 70vh !important;
  width: 80% !important;
}

.chat-input-part {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.chat-bottom-input-body {
  height: 90%;
}

.chat-bottom-drop
  .ant-dropdown
  .ant-dropdown-menu
  .ant-dropdown-menu-title-content {
  font-size: 8px !important;
  background: #07081b !important;
}

.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-title-content {
  font-size: 8px !important;
  text-align: center !important;
  color: var(--white-color) !important;
}

.ant-dropdown .ant-dropdown-menu,
.ant-dropdown-menu-submenu .ant-dropdown-menu {
  background-color: var(--chat_site_color) !important;
  color: var(--white-color) !important;
}

.ant-dropdown-menu-submenu .ant-dropdown-menu {
  background-color: var(--chat_site_color) !important;
  color: var(--white-color) !important;
}

.chat-bottom-drop.ant-dropdown-menu-submenu
  .ant-dropdown-menu
  .ant-dropdown-menu-submenu-title {
  font-size: 8px !important;
  background: #07081b !important;
}

.ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-submenu-title {
  font-size: 8px !important;
}

.ant-drawer-content.chat-mobile-bottom-draw .ant-drawer-header {
  display: none;
}

.chat-mobile-bottom-draw .ant-drawer .ant-drawer-content-wrapper {
  width: 100vw !important;
}

.chat-mobile-bottom-draw .ant-drawer .ant-drawer-content {
  width: 100vw !important;
}

.chart-part-mobile {
  height: 69vh;
}

.chat-bottom-input-footer {
  /* height: 10%; */
  margin-left: 0px !important;
  margin-right: 0px !important;
  padding: 5px;
  /* overflow-x: hidden; */
}

.chat-input-type {
  /* width: 100% !important; */
  display: flex;
  justify-content: center;
  background-color: var(--chat_site_color);
  border: none;
  /* border-radius: 4px; */
}

.chat-input-type textarea {
  border-radius: 0px;
}

.chat-bottom-add {
  border-radius: 50% !important;
  width: 2rem !important;
}

.chat-mobile-pod-drawer > .ant-drawer .ant-drawer-body {
  padding: 15px;
}

.chat-mobile-bottom-draw .ant-drawer .ant-drawer-body {
  padding: 12px;
}

.chat-bottom-button {
  height: 31px !important;
  width: 100%;
}

.chat-info-drawer-row-bottom {
  border-bottom: 1px solid var(--gray_backgroud_color);
  padding-bottom: 10px;
}

.chat-mobile-navbar-close-button {
  display: flex;
  justify-content: center;
  height: 0.1rem;
}

/* start media query */

@media only screen and (max-width: 2560px) and (min-width: 2305px) {
  .volvex-avatar-list-collapse {
    overflow-y: auto;
    height: 63rem !important;
  }

  .side-scroll-container-chat {
    height: 61rem !important;
  }
}

@media only screen and (max-width: 2304px) and (min-width: 2161px) {
  .volvex-avatar-list-collapse {
    overflow-y: auto;
    height: 70rem !important;
  }

  .side-scroll-container-chat {
    height: 66rem !important;
  }
}

@media only screen and (max-width: 2160px) and (min-width: 1922px) {
  .volvex-avatar-list-collapse {
    overflow-y: auto;
    height: 50rem !important;
  }

  .side-scroll-container-chat {
    height: 49rem !important;
  }
}

@media only screen and (max-width: 1921px) and (min-width: 1681px) {
  .volvex-avatar-list-collapse {
    overflow-y: auto;
    height: 50rem !important;
  }

  .side-scroll-container-chat {
    height: 49rem !important;
  }
}

@media only screen and (max-width: 1680px) and (min-width: 1601px) {
  .volvex-avatar-list-collapse {
    overflow-y: auto;
    height: 38rem !important;
  }

  .side-scroll-container-chat {
    height: 38rem !important;
  }
}

@media only screen and (max-width: 1600px) and (min-width: 1471px) {
  .volvex-avatar-list-collapse {
    overflow-y: auto;
    height: 33rem !important;
  }

  .side-scroll-container-chat {
    height: 35rem !important;
  }
}

@media only screen and (max-width: 1470px) and (min-width: 1441px) {
  .volvex-avatar-list-collapse {
    overflow-y: auto;
    height: 31rem !important;
  }

  .side-scroll-container-chat {
    height: 34rem !important;
  }
}

@media only screen and (max-width: 1440px) and (min-width: 1367px) {
  .volvex-avatar-list-collapse {
    overflow-y: auto;
    height: 30rem !important;
  }

  .side-scroll-container-chat {
    height: 33rem !important;
  }
}

@media only screen and (max-width: 1366px) and (min-width: 1281px) {
  .volvex-avatar-list-collapse {
    overflow-y: auto;
    height: 28rem !important;
  }

  .side-scroll-container-chat {
    height: 29rem !important;
  }
}

@media only screen and (max-width: 1280px) and (min-width: 1081px) {
  .volvex-avatar-list-collapse {
    overflow-y: auto;
    height: 28rem !important;
  }

  .side-scroll-container-chat {
    height: 29rem !important;
  }
}

@media only screen and (max-width: 1080px) and (min-width: 1025px) {
  .volvex-avatar-list-collapse {
    overflow-y: auto;
    height: 28rem !important;
  }

  .side-scroll-container-chat {
    height: 29rem !important;
  }
}

@media only screen and (max-width: 1024px) and (min-width: 769px) {
  .volvex-avatar-list-collapse {
    overflow-y: auto;
    height: 28rem !important;
  }

  .side-scroll-container-chat {
    height: 29rem !important;
  }
}

@media only screen and (max-width: 768px) and (min-width: 427px) {
  .conversation-desktop {
    display: none !important;
  }

  .volvex-btn {
    display: none;
  }

  .volvex-avatar-list-collapse .ant-collapse-header-text {
    font-size: 17px;
  }

  .conversation-mobile {
    display: block !important;
    background-color: #080a1e !important;
  }

  img.navlogo.cursor-pointer {
    width: 123px !important;
  }

  .bottom-nav-bar {
    display: block !important;
  }

  .bottom-nav-bar-hide {
    display: none;
  }

  .ant-popover-inner {
    width: 19rem;
    background: #10112c !important;
    padding: 1.5rem !important;
  }
}

@media only screen and (max-width: 426px) and (min-width: 377px) {
  .conversation-desktop {
    display: none !important;
  }

  .volvex-btn {
    display: none;
  }

  .volvex-avatar-list-collapse .ant-collapse-header-text {
    font-size: 17px;
  }

  .conversation-mobile {
    display: block !important;
    background-color: #080a1e !important;
  }

  img.navlogo.cursor-pointer {
    width: 123px !important;
  }

  .bottom-nav-bar {
    display: none !important;
  }

  .ant-popover-inner {
    width: 19rem;
    background: #10112c !important;
    padding: 1.5rem !important;
  }
}

@media only screen and (max-width: 376px) and (min-width: 321px) {
  .conversation-desktop {
    display: none !important;
  }

  .volvex-btn {
    display: none;
  }

  .volvex-avatar-list-collapse .ant-collapse-header-text {
    font-size: 17px;
  }

  .conversation-mobile {
    display: block !important;
    background-color: #080a1e !important;
  }

  img.navlogo.cursor-pointer {
    width: 123px !important;
  }

  .bottom-nav-bar {
    display: none !important;
  }

  .ant-popover-inner {
    width: 19rem;
    background: #10112c !important;
    padding: 1.5rem !important;
  }
}

@media (max-width: 320px) {
  .conversation-desktop {
    display: none !important;
  }

  .volvex-btn {
    display: none;
  }

  .volvex-avatar-list-collapse .ant-collapse-header-text {
    font-size: 17px;
  }

  .conversation-mobile {
    display: block !important;
    background-color: #080a1e !important;
  }

  img.navlogo.cursor-pointer {
    width: 123px !important;
  }

  .bottom-nav-bar {
    display: none !important;
  }

  .ant-popover-inner {
    width: 19rem;
    background: #10112c !important;
    padding: 1.5rem !important;
  }
}
