.pro-body {
    background-color: #10112C !important;
    color: #fff !important;
    margin-top: 4rem ;
}

.pro-sub-body {
    background-color: #10112C !important;
    color: #fff !important;
    margin: 4rem;
    min-height: 90vh !important;
}

.token-cal {
    background-color: transparent !important;
}

.prompts-history-delete-icon {
    font-size: 17px;
    color: #fff;
    margin: -0.3rem -0.5rem;
}

.pro-sub-body .ant-picker .ant-picker-clear {
    color: #fff;
    background-color: #10112c;
}

.pro-sub-body .ant-picker .ant-picker-input>input,
.pro-sub-body .ant-picker .ant-picker-input>input::placeholder {
    color: #fff;
}

.token-card:hover {
    color: #fff !important;
    border-color: #848484 !important;
}

.aivolvex-dashboard-outline-setup {
    background: linear-gradient(to top, transparent, #848484, #848484) !important;
    border-radius: 10px;
    padding: 0.6px;
    display: inline-block;
    height: 80%;
}

.body-title {
    color: #FFF;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.token-cal .ant-picker {
    background-color: transparent !important;
    color: #fff !important;
    /* width: 100%; */
}

.token-cal .ant-picker:hover {
    background-color: transparent !important;
    color: #fff !important;
    border-color: #848484 !important;
}

.token-cal .ant-picker-dropdown .ant-picker-month-panel .ant-picker-cell-inner {
    color: #fff !important;
}

.token-cal .ant-picker-panel {
    background-color: #2a2c56 !important;
    color: #fff !important;
}

.token-cal .ant-picker-dropdown .ant-picker-cell-in-view {
    color: #fff !important;
}

.ant-picker-dropdown .ant-picker-cell-in-view {
    color: #fff !important;
}

.token-card-main {
    border-radius: 10px;
    border: none;
    background: #17193A !important;
    backdrop-filter: blur(5.627785682678223px) !important;
    color: #fff !important;
}

.agent-icon-button {
    background-color: #24264C !important;
    border: none;
}

.agent-icon {
    color: #fff;
}

.agent-button {
    display: flex;
    justify-content: end;
}

.agent-text {
    display: flex !important;
    justify-content: center !important;
    padding: 1rem !important;
    font-size: 18px !important;
    width: 100%;
}

.token-agent {
    width: 100% !important;
}

.token-card-main.ant-card-body {
    background: #17193A !important;
}

.token-card-main.ant-card .ant-card-body {
    padding: 9px !important;
}

.token-card {
    border-radius: 10px;
    border: none;
    border: 1.126px solid rgba(255, 255, 255, 0.61);
    background: #17193A;
    backdrop-filter: blur(5.627785682678223px);
    color: #fff !important;
}

.token-border {
    padding-left: 1px !important;
    padding-right: 1px !important;
}

.create-token {
    min-height: 90vh !important;
}

.create-token-body {

    display: flex;
    justify-content: center;
}

.sub-header {
    padding-top: 10px;
}

.create-text {
    color: #fff;
    font-size: 18px;

}

.agent-header-button {
    width: 100%;

}

.agent-header-button-text-two {
    display: flex;
    justify-content: start;
}

.back-icon {
    font-size: 24px;

}

.delete-icon {
    color: white !important;
}

.delete-icon-button {
    background: #FB4B4B !important;
}

.back-icon-sub {
    display: flex;
    align-items: center;
}

.agent-header-button-text {
    display: flex;
    justify-content: end;
}

.agent-header-button-design {
    border-radius: 4px;
    background: #292B55;
    border: none;
    color: #fff;
}

.agent-header-button-complete-design {
    border-radius: 4px;
    background: #D736D18F;
    border: none;
    color: #fff;
}


.center-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 90vh;

}

.center-wrapper-add {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 38vh;

}

.save-button-token {
    background: #D736D1 !important;
}

.token-button .ant-input {
    background: transparent !important;
    border: none !important;
}

.agent-add {
    display: flex;
    justify-content: center;
}

.token-agent {
    margin-top: 4rem;
}


.icon-create {
    color: #fff !important;
    font-size: 24px !important;
    padding-right: 6px;
    padding-top: 4px;
}

.create-modal .ant-modal-content {
    background-color: #17193A !important;
    /* background: rgba(0, 0, 0, 0.31) !important; */
}

.create-modal .ant-card {
    background-color: #17193A !important;
    border: none !important;
}

/* background: rgba(0, 0, 0, 0.31) !important; */
.create-card-text {
    background-color: #17193A !important;
}

.create-card-text .ant-card {
    background: #17193A !important;
    border: none;
    color: #fff !important;
    width: 300px;
    height: 200px;
    text-align: center !important;
}

.create-token-button {
    width: 100% !important;
    background: rgba(255, 255, 255, 0.06) !important;
    border: none;
    color: #fff !important;

}

.token-header {
    text-align: center !important;
    color: #fff;
    font-size: 20px;
    font-weight: 400;
    display: flex;
    justify-content: center;
}

.create-btn {
    border-radius: 4px;
    background: rgba(215, 54, 209, 0.56) !important;
    color: #fff !important;
    font-size: 18px;
    border: none;
    padding: 2px 2px;
    width: 10rem;
    display: flex;
    justify-content: center;
}


.token-button {
    padding: 5px 5px;
}

.token-card-title-text {
    display: flex;
    justify-content: space-between;
    /* justify-content: center; */

    font-size: 23px;
    font-style: normal;
    font-weight: 600;
}

.token-card-title {
    display: flex;
    justify-content: center;
    font-size: 23px;
    font-style: normal;
    font-weight: 600;
}

.token-card-main .ant-btn {
    padding: 0px 7px !important;
}

.token-success {
    color: #fff !important;
    /* background-color: #D736D1 !important; */
    background-color: #24264C;
    border-radius: 6px;
    border: none !important;
    /* padding: 5px; */
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

}

.token-first {
    border-radius: 6px;
    color: #fff !important;
    width: 100% !important;
    font-size: 18px;

}

.token-card-title .ant-avatar.ant-avatar-icon>.anticon {
    vertical-align: 0.2em !important
}

.agent-header-button-design:hover {
    border-color: #848484 !important;
    color: #fff !important;
}

.agent-header-button-complete-design:hover {
    color: #fff !important;
}

.credit-button {
    padding: 5px;
    justify-content: center;
}

.credit-image {
    padding-left: 2px;
}

.credit-text {
    color: #D736D1 !important;
}

.token-credits-text {
    text-align: center;
}

.agent-text-select {
    width: 100%;
    padding: 5px;
    font-size: 18px;
    padding-top: 1.4rem !important;
}

.agent-text-select.ant-select .ant-select-arrow {
    color: #848484 !important;
    padding: 5px;
    padding-top: 15px;
}

.token-credits-text {
    cursor: pointer;
}

.log-body {
    width: 100% !important;
}

.text-body-log {

    background-color: #ffffff0f !important;
    color: #fff !important;
    border: none !important;
    border-radius: 4px;
    padding: 5px;

}

.text-right-text {
    display: flex;
    justify-content: end;
    padding: 5px;
    width: 100%;
}

.text-left-text {
    display: flex;
    justify-content: start;
    padding: 5px;
    width: 100%;
}

/* .ant-select .ant-select-arrow {
    color: #848484 !important;
} */

.agent-text-select.ant-select .ant-select-arrow:hover {
    border: #848484 !important;
}

.agent-text-select .ant-select {
    width: 100% !important;
}

@media only screen and (max-width: 768px) and (min-width: 427px) {
    .pro-body {
        background-color: #10112C !important;
        color: #fff !important;
        margin-top: 0rem !important;
    }

    .pro-sub-body {
        background-color: #10112C !important;
        color: #fff !important;
        margin: 1rem 2rem;
        min-height: 90vh !important;
    }
    .token-cal .ant-picker {
        background-color: transparent !important;
        color: #fff !important;
        width: 100%;
    }
}

@media only screen and (max-width: 426px) and (min-width: 377px) {
    .pro-body {
        background-color: #10112C !important;
        color: #fff !important;
        margin-top: 0rem !important;
    }

    .pro-sub-body {
        background-color: #10112C !important;
        color: #fff !important;
        margin: 1rem 2rem;
        min-height: 90vh !important;
    }
    .token-cal .ant-picker {
        background-color: transparent !important;
        color: #fff !important;
        width: 100%;
    }
}

@media only screen and (max-width: 376px) and (min-width: 321px) {
    .pro-body {
        background-color: #10112C !important;
        color: #fff !important;
        margin-top: 0rem !important;
    }

    .pro-sub-body {
        background-color: #10112C !important;
        color: #fff !important;
        margin: 1rem 2rem;
        min-height: 90vh !important;
    }
    .token-cal .ant-picker {
        background-color: transparent !important;
        color: #fff !important;
        width: 100%;
    }
}

@media (max-width: 320px) {
    .pro-body {
        background-color: #10112C !important;
        color: #fff !important;
        margin-top: 0rem !important;
    }

    .pro-sub-body {
        background-color: #10112C !important;
        color: #fff !important;
        margin: 1rem 2rem;
        min-height: 90vh !important;
    }
    .token-cal .ant-picker {
        background-color: transparent !important;
        color: #fff !important;
        width: 100%;
    }
}