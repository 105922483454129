.signInCarousel1Image,
.signInCarousel3Image,
.signInCarousel2Image {
  // height: 100% !important;
  // height: 673px !important;
  // min-height: 100vh;
  height: 100vh;
  width: 100% !important;
}

.signInCarousel2Image {
  width: 32rem;
}

.new-banner-image-back {
  // background: #17193a;
  background: #07081b !important;
  // background-color: #000 !important;
  //  background-image: linear-gradient(150deg, #2c0e34 5%, #07081b 44%) !important;
}

.signInCarousel3Image {
  width: 25rem;
}

.splide__pagination__page {
  background: #f6ecec;
  border: 0;
  // border-radius: 50%;
  display: inline-block;
  height: 8px;
  margin: 3px;
  opacity: 0.7;
  padding: 0;
  position: relative;
  transition: transform 0.2s linear;
  width: 5rem;
}

.splide__pagination__page {
  background: #f6ecec;
  border: 0;
  /* border-radius: 50%; */
  display: inline-block;
  height: 8px;
  margin: 3px;
  opacity: 0.7;
  padding: 0;
  position: relative;
  transition: transform 0.2s linear;
  width: 5rem;
}

.splide__pagination__page.is-active {
  background: linear-gradient(90deg, #f33a56 -1.19%, #6e039f 164.88%);
  transform: scale(1.4);
  z-index: 1;
}


// .signInCarousel1Image,
// .signInCarousel3Image,
// .signInCarousel2Image{
//   width: 100% !important;
//   max-width: 720px !important; 
//   height: auto; 
//   margin: 0 auto; 
//   display: block !important;
// }

// @media (min-width: 769px) and (max-width: 1440px) {
//   .signInCarousel1Image,
//   .signInCarousel3Image,
//   .signInCarousel2Image{
//     width: 50%;
//     margin: 0 auto;  
//   }
// }


// @media (min-width: 1441px) {
//   .signInCarousel1Image,
//   .signInCarousel3Image,
//   .signInCarousel2Image {
//     width: 720px; 
//     margin: 0 auto; 
//   }
// }
.signInCarousel1Image,
.signInCarousel3Image,
.signInCarousel2Image {

  width: 50vw !important;
  height: 100vh !important;
}