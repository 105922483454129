@import url("https://fonts.cdnfonts.com/css/axiforma");

.Dashboard {
  display: grid;
  grid-template-columns: 0.7fr 3fr;
  height: 89vh;
}

.DashboardContent {
  height: 89vh;
  overflow-y: scroll;
}

// .DashboardContent::-webkit-scrollbar {
//   display: none;
// }
.DashboardContent {
  padding: 3rem;
}

.Dashboard_modal {
  margin-top: 1rem;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 3rem;
  width: 126%;
}

.Dashboard_graph {
  margin-top: 1rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1rem;
}

.Dashboard_head_box_shadow {
  box-shadow: 0px 39px 116px rgba(28, 56, 146, 0.14) !important;
}

.Dashboard_head_box {
  background-color: #17193a;
  // padding: 1rem 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-radius: 10px;
  padding: 0.25rem 1rem 0.25rem 1rem;

  & h2 {
    color: #fff;
    font-size: 20px;
  }

  & p {
    color: #fff;
    // color: rgb(172, 172, 172);
  }

  & img {
    height: 30px;
    width: 30px;
  }
}

.Dashboard_body {
  margin-top: 3rem;
  display: grid;
  grid-gap: 1rem;
}

.Dashboard_graph_box {
  background-color: #17193a;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-radius: 10px;
  padding: 1rem;
  backdrop-filter: blur(5.627785682678223px);
}

.dashboard-datepicker {
  height: 75%;
  background-color: #2a2c56 !important;
  color: #fff !important;
}

.dashboard-datepicker .ant-picker-panel {
  background-color: #2a2c56 !important;
  color: #fff !important;
}

.dashboard-datepicker .ant-picker-dropdown .ant-picker-panel-container {
  background-color: #2a2c56 !important;
  color: #fff !important;
}

.ant-picker-dropdown .ant-picker-panel-container {
  background-color: #2a2c56 !important;
  color: #fff !important;
}

.dashboard-datepicker .ant-picker-dropdown .ant-picker-cell-in-view {
  border: none !important;
}

.dashboard-datepicker .ant-picker-dropdown .ant-picker-month-panel .ant-picker-cell-inner {
  color: #fff !important;
}

.ant-picker-dropdown .ant-picker-month-panel .ant-picker-cell-inner {
  color: #fff !important;
}

.ant-picker-dropdown .ant-picker-header-view button {
  color: #fff !important;
}

.dashboard-datepicker.ant-picker .ant-picker-input>input {
  color: #fff !important;
}

.ant-picker .ant-picker-suffix {
  color: #fff !important;
}

// .dashboard-datepicker.ant-picker tbody,
// td,
// tfoot,
// th,
// thead,
// tr {
//   border: none !important;
// }
.dash-card {
  padding: 10px;
}

.ant-picker-dropdown .ant-picker-header>button {
  color: #fff;
}

//aivolvex-dashboard-body
.Dashboard_count_graph {
  background-color: #292b57;
  /* display: flex; */
  align-items: center;
  /* justify-content: center; */
  width: 100%;
  border-radius: 10px;
  padding: 0.25rem 1rem 0.25rem 1rem;
  //   margin-top: 1rem;
}

.apexcharts-menu-icon {
  display: none;
}

.chart-dropdown-icon-text {
  font-size: 21px;
}

.chart-dropdown {
  margin-left: 95%;
  font-size: 21px;
  //  float:right !important;
}

.aivolvex-dashboard-body {
  padding: 0rem 5rem 3rem;
  background: #10112c;
}

.aivolvex-dashboard-outline {
  background: linear-gradient(to top,
      transparent,
      rgb(255, 255, 255),
      rgb(255, 255, 255));
  border-radius: 10px;
  padding: 0.6px;
  display: inline-block;
}

// .chart-dropdown-btn{
//   background-color: #fff;
//   color:#0000;
//   border: none;
// }

.dashboard-credit {
  color: #db2aac;
  font-weight: 600;
}

.modalcard1 {
  width: 50%;
}

.dashboard-sub-header {
  font-weight: 400;
  font-size: 21px;
}

.table-usage {
  margin: 1rem;
}

.aivolvex-dashboard-modeltoken-scroll {
  height: 11rem;
  overflow-y: auto;
}

.aivolvex-dashboard-modeltoken-scroll::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

.aivolvex-dashboard-modeltoken-scroll::-webkit-scrollbar-track {
  background-color: #fae8f6;
  border-radius: 0.5rem;
}

.aivolvex-dashboard-modeltoken-scroll::-webkit-scrollbar-thumb {
  background-color: #9a9a9a;
  border-radius: 10px;
  border: 2px solid #fae8f6;
}

.table-usage.ant-table-wrapper .ant-table-thead>tr>td {
  color: #ff6a2f;
  text-align: center;
  border: none;
}

.table-usage.ant-table-wrapper .ant-table-tbody>tr>td {
  background-color: #202020 !important;
  border-top: 0.474px solid #84848484;
  border-bottom: transparent !important;
}

.table-usage .ant-pagination .ant-pagination-prev {
  border: 1px solid #848484;
  margin: 0px;
  padding-right: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 7px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.info-icon {
  color: grey;
  position: absolute;
  top: 0;
  right: 0;
  margin-right: 10px;
  margin-top: 10px;
  height: 8%;
  width: 8%;
}

.aivolvex-dashboard-model-token {
  color: #fff;
}