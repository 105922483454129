:root {
  --chat_site_color: #17193a;
  --chat_background_color: #080a1e;
  --chat_lite_site_color: #e7e7e7;
  --white-color: #fff;
  --purple-color: #d736d1;
  --gray_backgroud_color: #3a3d63;
}

.cursor {
  cursor: pointer !important;
}

.dashboard-board-background {
  /* background: #10112C; */
  /* background-color: #17193A; */
  background-color: #1d1f43;
  /* padding: 10px; */
  margin-top: 3.5rem;
}

.dashboard-board-private {
  margin-top: 5rem;
  padding: 6px;
}

/* background: #10112C; */
.chatbot-dashboard-main-body {
  /* width: 100%; */
}

.chatdashboard-record-dection {
  padding: 10px;

  /* width: 100%; */
}

.chatdashboard-record-dection .ant-tabs {
  color: #848484;
}

.chatdashboard-record-dection .ant-tabs .ant-tabs-tab:hover {
  color: #848484 !important;
}
.chart-icon-voice {
  color: #1faeeb;
}
.chart-icon-chat {
  color: #8ac926;
}
.chatdashboard-record-dection .ant-tabs .ant-tabs-tab {
  font-size: 22px !important;
  font-weight: 600;
  width: 15rem;
  justify-content: center;
}

.chatbot-dashboard-card {
  padding: 7px;
  border-radius: 7px;
  padding-left: 1rem !important;
  border: none;
  background: #17193a;
  backdrop-filter: blur(5.627785682678223px);
  /* line-height: 1.8rem !important; */
  height: 100%;
}

.chatbot-dashboard-card-chart {
  height: 100%;
}

.chatbot-dashboard-card-button {
  border-radius: 50%;
  border-color: var(--purple-color);
  /* height: 2rem; */
  background-color: #d736d11f;
  padding: 6px;
  height: 3.5rem;
  width: 3.5rem;
}

.aivolvex-chatbot-dashboard-outline-setup {
  background: linear-gradient(to top, transparent, #848484, #848484) !important;
  border-radius: 7px;
  padding: 0.8px;
  display: inline-block;
  height: 100%;
  width: 100%;
}

.chatbot-dashboard-card-icon {
  color: var(--purple-color);
  padding-bottom: 2px;
}

.chatbot-dashboard-card-text-primary {
  font-size: 17px;
  color: var(--white-color);
  font-weight: 400;
}

.chatbot-dashboard-card-text-secondary {
  font-size: 20px;
  color: var(--white-color);
  font-weight: 500;
}

.chatbot-dashboard-card-chart-header {
  font-size: 16px;
  font-weight: 500;
  color: #fff;
}

.dashboard-board-background-table {
  background: #10112c;
  /* padding: 5px; */
}

.ant-tag-purple {
  border-radius: 12.509px;
  border: 0.834px solid #d736d1;
  /* color: #D736D1; */
  color: #fff;
  background: rgba(215, 54, 209, 0.2);
}

.ant-tag-blue {
  border-radius: 12.509px;
  border: 0.834px solid #1ba9d6;
  /* color: #1BA9D6;
     */
  color: #fff;
  background: rgba(54, 138, 215, 0.2);
}

.ant-table-wrapper .ant-table-pagination.ant-pagination {
  margin: 0px;
  padding-top: 16px;
  padding-bottom: 5px;
  /* background-color: #17193a; */
  background: transparent;
  border-bottom: 4px;
}

.record-history-tab .ant-tabs .ant-tabs-tab {
  font-size: 16px !important;
  font-weight: 500;
  width: 7rem;
  justify-content: center;
}

.record-history-tab .ant-tabs {
  color: #848484;
}

.view-more {
  color: var(--purple-color);
}

.record-history-tab .ant-tabs .ant-tabs-content {
  max-height: 60vh;
  overflow-y: scroll;
}

.volvex-private-podlist {
  /* background-color: #252746; */
  padding: 1rem 0rem 0rem;
  background: #10112c;
}

.chatbot-dashboard-card-item {
  padding: 6px;
}

.summary-para {
  display: flex;
  justify-content: center;
}

.history-record-table .ant-pagination-item-link .anticon {
  color: #fff;
}

.report-table-modal .ant-modal-content {
  /* background-color: #07081B !important; */
}

.report-table-modal .ant-modal .ant-modal-body {
  /* background: #07081B !important; */
}

/* .history-record-table {
    border: none;
}

.history-record-table .ant-table-wrapper .ant-table {
    background: #24264c !important;
    color: #fff;

}

.history-record-table .ant-table-thead>tr>th {
    background: #07081B !important;
    color: #fff !important;
    transition: background 0.2s ease;
    border-bottom: transparent;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
}

.history-record-table .ant-table-wrapper .ant-table-thead>tr>td {
    background: #07081B !important;
    color: #fff !important;
    border: 1px solid #07081B;
   
}

.history-record-table .ant-table-wrapper .ant-table-tbody>tr>td.ant-table-cell-row-hover {
    background-color: transparent;
}

.history-record-table .ant-table-wrapper .ant-table-pagination.ant-pagination {
    background: #24264c !important;
} */

/* .ant-table-wrapper .ant-table-thead >tr>td { */
.history-record-table .ant-table-wrapper .ant-table-pagination.ant-pagination {
  background: #24264c !important;
}

.dashboard-board-private .ant-collapse {
  border: none !important;
  color: #fff !important;
}

.dashboard-board-private .ant-collapse > .ant-collapse-item {
  border: none !important;
  color: #fff !important;
}

.dashboard-board-private
  .ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header {
  color: #fff !important;
  font-size: 20px;
  font-weight: 500px;
  font-family: "Poppins";
}

.dashboard-board-private .ant-collapse .ant-collapse-content {
  color: #fff !important;
  background: transparent;
}

.dashboard-board-private .ant-collapse .ant-collapse-content {
  border-top: none;
}

.summary-text {
  color: #fff;
  font-family: "Poppins";
  padding: 1rem 5rem;
  /* text-align: center; */
}

.chatdashboard-record-dection
  .ant-tabs
  .ant-tabs-tab-btn:focus:not(:focus-visible) {
  color: #848484;
}

.chatdashboard-record-dection
  .ant-tabs
  .ant-tabs-tab-remove:focus:not(:focus-visible) {
  color: #848484;
}

.chatdashboard-record-dection .ant-tabs .ant-tabs-tab-btn:active {
  color: #848484;
}

.chatdashboard-record-dection .ant-tabs .ant-tabs-tab-remove:active {
  color: #848484;
}

.record-history-tab .ant-tabs .ant-tabs-tab-btn:focus:not(:focus-visible) {
  color: #848484;
}

.record-history-tab .ant-tabs .ant-tabs-tab-remove:focus:not(:focus-visible) {
  color: #848484;
}

.record-history-tab .ant-tabs .ant-tabs-tab-btn:active {
  color: #848484;
}

.record-history-tab .ant-tabs .ant-tabs-tab-remove:active {
  color: #848484;
}

.record-history-tab .ant-tabs .ant-tabs-tab:hover {
  color: #848484 !important;
}

.history-tab-row {
  padding: 5px;
  border-bottom: 1px solid#848484;
}

.puple-font {
  color: var(--purple-color);
}

.white-font {
  color: var(--white-color);
}

.text-end {
  display: flex;
  justify-content: end;
}

.record-dashboard-table {
  width: 100%;
  overflow-x: scroll;
}

.dashboard-board-background-table::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.dashboard-board-private
  .ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-expand-icon {
  height: 31px;
}
.record-dashboard-table thead th {
  /* background-color: #07081b !important;
    border-radius: 13.226px 13.226px 0px 0px; */
  background: rgba(255, 255, 255, 0.06) !important;

  /* background-color: #fffe00; */
  color: #fff !important;
  border: none !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 100% !important;
  white-space: nowrap;
  /* text-align: center !important; */
  z-index: 0 !important;
}

.record-dashboard-table .ant-table {
  background: transparent !important;
  color: #fff;
}

.record-dashboard-table tbody tr td {
  /* border-bottom: 1px solid #999; */
  border: none;
  border-bottom: 1px solid #545454 !important;
}

.record-dashboard-table .ant-table-cell-row-hover {
  background: transparent !important;
}

.record-dashboard-table a:hover {
  color: var(--purple-color);
}

.question-font {
  font-weight: 700;
}

.text-line-break {
  word-wrap: break-word;
  overflow: auto;
  white-space: pre-wrap;
}
.record-dashboard-table
  .ant-pagination
  .ant-pagination-disabled:hover
  .ant-pagination-item-link {
  color: #fff;
}

.record-dashboard-table .ant-pagination {
  color: #fff;
}

.record-dashboard-table
  .ant-pagination
  .ant-pagination-prev
  .ant-pagination-item-link {
  color: #fff;
}

.record-dashboard-table
  .ant-pagination
  .ant-pagination-next
  .ant-pagination-item-link {
  color: #fff;
}

.record-dashboard-table .ant-pagination .ant-pagination-item a {
  color: #fff !important;
  background: transparent !important;
}

.record-dashboard-table .ant-pagination .ant-pagination-item-active {
  color: #fff !important;
  background: transparent !important;
}

.record-dashboard-popup
  .ant-popconfirm
  .ant-popconfirm-message
  .ant-popconfirm-description {
  color: #fff !important;
}

.ant-popconfirm .ant-popconfirm-message .ant-popconfirm-description {
  color: #fff;
}

/* .ant-modal .ant-modal-close {
    background-color: var(--purple-color) !important;  
} */

.report-table-modal .ant-modal-content .ant-modal-close {
  background-color: var(--purple-color) !important;
}

.smiley-text {
  padding-top: 0.12rem;
  padding-left: 0.2rem;
}
.ant-tag-green {
  color: #fff;
  border-radius: 12.509px;
  border-color: #2fb14c;
  background: rgba(47, 177, 76, 0.34);
}
.ant-tag-red {
  color: #fff;
  border-radius: 12.509px;
  border-color: #ff3f32;
  background: rgba(255, 121, 121, 0.2);
}
.fh-100 {
  height: 100vh;
}
.content-formate {
  word-wrap: break-word;
  white-space: pre-wrap;
  overflow: auto;
}
/* border-radius: 12.509px;
border: 0.834px solid #FF3F32;
background: rgba(255, 121, 121, 0.20); */
.history-token-logo {
  width: 25px;
}
